.loginpage-wrapper {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  width:100%;
  height:100vh;
  background-color: #F5F5F9;
}
.login-side-left {
  height:100vh;
  flex:1.7;
  background-color: #F5F5F9;
  position: relative;
  // width: 50%;
}

.login-side-right {
  border:1px solid transparent;   
  position: relative;
  flex:1;
  background-color: rgb(255,255,255);
  // box-shadow:0px 0px 10px 1px grey;
  border-radius: 20px 0 0 20px;
  padding: 3rem;
  overflow-x:hidden;

  .login-side-right-wrapper {
      
      width:450px;

      .signin{
          display: none;
          &.show {
              display: block;
              animation: animateShiftRightContainer 1.2s forwards;
          }
      }
      .forgotpass {
          display: none;
          &.show {
              display: block;
              animation: animateShiftRightContainer 1.2s forwards;
          }
      }

      .login-side-right-title {
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 700;
          font-size: 1.75rem;
          color:rgba(50, 71, 92, 0.87);
          margin-bottom: 2rem;
      }

      .login-side-right-subtitle {
          font-weight: 500;
          font-size:1.25rem;
          margin-bottom: 0.375rem;
      }

      .login-side-right-p {   // title Sign In
          margin:0px 0px 1.5rem;
          font-weight: 400;
          font-size:1rem;
          line-height: 1.5;
          color: rgba(50, 71, 92, 0.6);
      }
      .login-side-right-p-forgot {    // title Forgot Password
          margin:-0.5rem 0px 1.5rem;
          font-weight: 400;
          font-size:1rem;
          line-height: 1.5;
          color: rgba(50, 71, 92, 0.6);
      }

      // .forgotpass {
      //     display: none;
      //     &.show {
      //         display: block;
      //     }
      // }
      // .signin {
      //     display: none;
      //     &.show {
      //         display: block;
      //     }
      // }
  }
}


@keyframes animateShiftContainer {
  from {
      transform: translateX(-100%);
      opacity: 0.2;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes animateShiftRightContainer {
  from {
      position: relative;
      left:100%;
      // transform: translateX(100%);
      opacity: 0.2;
  }
  to {
      // transform: translateX(0);
      position: relative;
      left:0;
      opacity: 1;
  }
}

.login-img-side-left {
  // background: url('../../assets/images/boy-with-rocket-light.png') no-repeat center;
  background: url('../../assets/images/pe.png') no-repeat center;
  position: absolute;
  display: none;
  // background-size: contain;
  background-size: cover;
  width:100%;
  height:100%;
  transition: all .5s ease;
  animation: animateShiftContainer 1.2s forwards;

  &.show {
      display: block;
  }
}
.login-img-side-left-forgotpass {
  background: url('../../assets/images/girl-unlock-password-light.png') no-repeat center;
  position: absolute;
  display: none;
  background-size: contain;
  width:100%;
  height:100%;
  transition: all .5s ease;
  animation: animateShiftContainer 1.2s forwards;

  &.show {
      display: block;
  }
}


.form-custom {
  position: relative;
  top:7px;
}

.forgot-password {
  color :rgb(0 74 173) !important;
  text-decoration: none;
  font-size:0.875rem !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor:pointer;
  font-weight: 500;
}
.back-to-login {
  color :rgb(0 74 173) !important;
  text-decoration: none;
  font-size:1rem !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor:pointer;
  font-weight: 500;
}

.button-sign-in {
  width: 100%;
  background-color: rgb(0 74 173) !important;
  border-radius: 8px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding: 0.5rem 1.65rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin:1.5rem 0 1rem !important;

  &:disabled {
    background-color: rgb(196 197 227) !important
  }
}

@keyframes animateLogoContainer {
  from {
      transform: translateX(-500%);
      opacity: 0.2;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.login-side-right-logo {
  position: relative;
  // position: absolute;
  top:-30px;
  // right:20px;
  width: 100%;
  display: block;
  text-align: center;
  // border: 1px solid red;
  z-index: 2;

  .logo-class {
    animation: animateLogoContainer 1.2s forwards;
  }
  // filter:drop-shadow(3px 3px 5px rgb(240, 246, 160))
  
}

// @media (max-width: 1028px) {

//   .login-side-right-logo {
//       text-align: right;
//       right:20px;
//       .logo-class {
//           width:150px;
//       }
//   }
// }


// @media (max-width: 1000px) {

//   .login-side-right-logo {
//       display: none;
//   }
// }

@media (max-width: 768px) {
  .login-side-left {
      display: none;
  }

}
